import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import moment from 'moment'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import VueApexCharts from 'vue-apexcharts'
import JsonExcel from "vue-json-excel";
import VueMask from 'v-mask'
import error_alert from "./components/error_alert.vue";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import './registerServiceWorker'

Vue.prototype.moment = moment

require('./store/subscriber')

Vue.config.productionTip = false


const token = JSON.parse(localStorage.getItem('vuex'))?.auth?.token ?? null
store.dispatch('auth/attempt', token)


Vue.use(Vuelidate)

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueMask);
Vue.component("error_alert", error_alert);
Vue.mixin({
  filters: {
    formatDate(value) {
      if (!value) return '';
      return moment(value).format("DD.MM.YYYY")
    },
    formatBool(value) {
      if (!value) return 'Нет';
      return 'Да'
    },
  },
  data: () => ({
    decimalMask: createNumberMask({
      prefix: "",
      includeThousandsSeparator: true,
      allowNegative: false,
      thousandsSeparatorSymbol: " ",
    }),
  }),
  methods: {
    numberWithSpaces(x) {
      // Разделяет число по разрядам 123123 -> 123 123
      if (x) {
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
      } else {
        return 0
      }
    },
    sumField(items, key) {
      return items
        .map((item) => item[key])
        .reduce((prev, curr) => prev + curr, 0);
    },
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
